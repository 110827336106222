import React, { memo, useEffect } from 'react';

import { Stack } from '@atlaskit/primitives';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { type NLPSearchTeamResultDataType } from '../ai-answer-dialog/types';
import { getTypedJSONResultData } from '../ai-answer-dialog/utils/getTypedJSONResultData';
import { useAnalytics } from '../common/controller/analytics';

import { onKnowledgeCardShown } from './analytics';
import { KnowledgeCardsBody } from './knowledge-cards-body';
import { KnowledgeCardsHeader } from './knowledge-cards-header';
import { KnowledgeCardsIntentType } from './types';

export const TeamKnowledgeCard = memo(() => {
	const {
		commonAttributes,
		userDetails: { id: currentUserId },
	} = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const { answerString } = commonAttributes;
	const data = getTypedJSONResultData(answerString).parsedResult as NLPSearchTeamResultDataType;

	useEffect(() => {
		fireAnalyticsEvent(
			onKnowledgeCardShown({
				...commonAttributes,
				cardType: KnowledgeCardsIntentType.TEAM,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack space="space.100">
			<KnowledgeCardsHeader
				type={KnowledgeCardsIntentType.TEAM}
				name={data.name}
				avatarUrl={data.avatarUrl}
				profileUrl={data.url}
			/>
			<KnowledgeCardsBody
				type={KnowledgeCardsIntentType.TEAM}
				currentUserId={currentUserId || ''}
				recentActivity={data.recentActivity}
				recommendedUsers={data.members}
				profileUrl={data.url || ''}
				viewAllUrl={data.url || ''}
			/>
		</Stack>
	);
});
