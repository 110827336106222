import React, { useEffect, useMemo } from 'react';

import { onAIResultViewed } from '../../common/controller/analytics/unified-analytics';
import { CardTemplate } from '../../constants';
import { useAIAnswerContext } from '../ai-answer-context';
import { NLPSearchResultFormat } from '../types';

import {
	AIAnswerError,
	JsonResultComponent,
	PlaintextResultComponent,
	RenderedResultComponent,
} from './ai-answer-result';
import { AIAnswerBodyWrapper, AIAnswerErrorWrapper } from './styled';

const AIAnswerResult = () => {
	const { commonAttributes, fireAnalyticsEvent, isReadingAids } = useAIAnswerContext();
	const { answerFormat, searchSessionId, singleInstrumentationId, source } = commonAttributes;

	useEffect(() => {
		fireAnalyticsEvent(
			onAIResultViewed({
				searchSessionId,
				singleInstrumentationId,
				isReadingAids: !!isReadingAids,
				source,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AIAnswerResultComponent = useMemo(() => {
		switch (answerFormat) {
			case NLPSearchResultFormat.ADF:
			case NLPSearchResultFormat.MARKDOWN:
				return RenderedResultComponent;
			case NLPSearchResultFormat.JSON:
				return JsonResultComponent;
			case NLPSearchResultFormat.PLAINTEXT:
			default:
				return PlaintextResultComponent;
		}
	}, [answerFormat]);
	return <AIAnswerResultComponent />;
};

export const AIAnswerBody = () => {
	const { isAnswered, hasError, isReadingAids, cardTemplate } = useAIAnswerContext();
	return (
		<AIAnswerBodyWrapper isReadingAids={isReadingAids && cardTemplate === CardTemplate.DEFAULT}>
			{isAnswered && !hasError ? (
				<AIAnswerResult />
			) : (
				<AIAnswerErrorWrapper>
					<AIAnswerError />
				</AIAnswerErrorWrapper>
			)}
		</AIAnswerBodyWrapper>
	);
};
