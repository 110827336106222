import type React from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { setSessionItem } from '@atlassian/search-dialog';

import { type AIAnswerContextType } from '../ai-answer-context';
import { DEFAULT_EMPTY_NLP_SEARCH_STATE, SEARCH_AI_ANSWER_CACHE_KEY } from '../ai-answer-dialog';
import {
	getNonStreamFetchErrorEventPayload,
	getStreamFetchErrorEventPayload,
} from '../error-analytics';
import {
	type AIAnswerQueryFunctionType,
	type AIAnswerQueryStreamResponse,
	AIAnswerQueryStreamType,
	NLPSearchErrorState,
	NLPSearchResultFormat,
	type NLPSearchType,
	type parsedFiltersType,
} from '../types';

export type FetchAIAnswerArgs = {
	cloudId: string;
	additional_context?: string;
	locale: string;
	query: string;
	source: string;
	followUpsEnabled?: boolean;
	getAIAnswer: AIAnswerQueryFunctionType;
	setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	partialResponse: AIAnswerContextType['partialResponse'];
	partialResponseAbortControllerRef: React.MutableRefObject<AbortController | undefined>;
	setPartialResponse: React.Dispatch<React.SetStateAction<AIAnswerContextType['partialResponse']>>;
	setData: React.Dispatch<React.SetStateAction<NLPSearchType>>;
	setAnswerStreamed: React.Dispatch<React.SetStateAction<boolean>>;
	parsedFilters?: parsedFiltersType;
	isReadingAids: boolean;
	fireAnalyticsEvent: (payload: AnalyticsEventPayload) => void;
};

export const fetchAIAnswer = async ({
	cloudId,
	locale,
	query,
	additional_context,
	source,
	followUpsEnabled = false,
	getAIAnswer,
	setLoading,
	partialResponse,
	partialResponseAbortControllerRef,
	setPartialResponse,
	setData,
	setAnswerStreamed,
	parsedFilters,
	isReadingAids,
	fireAnalyticsEvent,
}: FetchAIAnswerArgs) => {
	setLoading(true);

	if (partialResponse?.partialNlpResult) {
		// clean up any old partial responses
		setPartialResponse(undefined);
	}
	// end any old partial response streams
	partialResponseAbortControllerRef.current?.abort();
	const abortController = new AbortController();
	partialResponseAbortControllerRef.current = abortController;

	// For graphQL getAIAnswer fns we expect each product to pass their own cloudIdARI in order for this component to be more product agnostic
	const aiAnswer = getAIAnswer({
		variables: {
			query,
			additional_context,
			locale,
			filters: parsedFilters,
			experience: isReadingAids ? 'readingAids' : source,
			followUpsEnabled,
		},
	});

	const processStreamResponse = async (answer: AIAnswerQueryStreamResponse) => {
		try {
			let next = await answer.stream.next();
			let answerFormat;

			while (!next.done) {
				const streamEvent = next.value;

				if (streamEvent.type === AIAnswerQueryStreamType.AnswerType) {
					answerFormat = streamEvent.message.format;
				}
				if (streamEvent.type === AIAnswerQueryStreamType.AnswerPart) {
					if (answerFormat !== NLPSearchResultFormat.MARKDOWN) {
						throw new Error(`Unexpected format: ${answerFormat}`);
					}

					setPartialResponse((previousPartialResponse) => {
						let previousPartialNlpResult: string = previousPartialResponse?.partialNlpResult || '';
						return {
							format: NLPSearchResultFormat.MARKDOWN,
							partialNlpResult: previousPartialNlpResult + streamEvent.message.nlpResult,
						};
					});
				}

				if (streamEvent.type === AIAnswerQueryStreamType.FinalResponse) {
					setPartialResponse(undefined);
					setData(streamEvent.message);
					setLoading(false);
				}

				next = await answer.stream.next();
				if (abortController.signal.aborted) {
					// eslint-disable-next-line no-throw-literal
					throw 'Aborted';
				}
			}

			const streamEnd = next.value;
			if (abortController.signal.aborted) {
				// eslint-disable-next-line no-throw-literal
				throw 'Aborted';
			}

			if (streamEnd.state === 'complete') {
				setPartialResponse(undefined);
				setData(streamEnd.value.message);
				setSessionItem(
					SEARCH_AI_ANSWER_CACHE_KEY,
					JSON.stringify({
						query: query,
						data: streamEnd.value.message,
					}),
				);
			} else {
				if (streamEnd.reason === 'timeout') {
					setPartialResponse(undefined);
					setData({
						errorState: NLPSearchErrorState.TimeoutError,
						format: null,
						disclaimer: null,
						nlpResults: [],
						uniqueSources: [],
						nlpFollowUpResults: null,
					});
				} else {
					throw streamEnd;
				}
			}
		} catch (error: unknown) {
			fireAnalyticsEvent(getStreamFetchErrorEventPayload(error, source));
			if (error === 'Aborted') {
				return;
			}
			setPartialResponse(undefined);
			setData({
				errorState: NLPSearchErrorState.NetworkError,
				format: null,
				disclaimer: null,
				nlpResults: [],
				uniqueSources: [],
				nlpFollowUpResults: null,
			});
		} finally {
			setAnswerStreamed(true);
			setLoading(false);
		}
	};

	// If aiAnswer is an object with a type property -- it's a getAIAnswer
	// and we need to handle the new streaming response
	if ('type' in aiAnswer) {
		processStreamResponse(aiAnswer);
		return;
	}

	await aiAnswer
		.then((data) => {
			if ('type' in data) {
				processStreamResponse(data);
				return;
			}

			setData(data?.nlpSearch || DEFAULT_EMPTY_NLP_SEARCH_STATE);
			setSessionItem(
				SEARCH_AI_ANSWER_CACHE_KEY,
				JSON.stringify({
					query: query,
					data: data?.nlpSearch || DEFAULT_EMPTY_NLP_SEARCH_STATE,
				}),
			);
			setLoading(false);
		})
		.catch((error: unknown) => {
			fireAnalyticsEvent(getNonStreamFetchErrorEventPayload(error, source));
			setData({
				errorState:
					error instanceof DOMException && error.name === 'TimeoutError'
						? NLPSearchErrorState.TimeoutError
						: NLPSearchErrorState.NetworkError,
				format: null,
				disclaimer: null,
				nlpResults: [],
				uniqueSources: [],
				nlpFollowUpResults: null,
			});
			setLoading(false);
		});
};
