import { NLPSearchResultFormat } from '../../../ai-answer-dialog';
import {
	type commonAttributesType,
	NLPSearchJSONResultFormat,
	type NLPSearchResultSource,
} from '../../../ai-answer-dialog/types';
import {
	getTypedJSONResultData,
	getTypedJSONResultDataAssistanceService,
} from '../../../ai-answer-dialog/utils/getTypedJSONResultData';
import { sha256Hash } from '../../../utils';

type GetCardTypeParams = {
	isReadingAids: boolean;
	answerFormat: NLPSearchResultFormat | null;
	parsedType: NLPSearchJSONResultFormat | null;
};

type getCardTypeReturn = 'definition' | 'people' | 'work' | 'SAIN';

export const getCardType = ({
	isReadingAids,
	answerFormat,
	parsedType,
}: GetCardTypeParams): getCardTypeReturn => {
	if (isReadingAids) {
		return 'definition';
	}

	if (answerFormat === NLPSearchResultFormat.JSON && parsedType === NLPSearchJSONResultFormat.WHO) {
		return 'people';
	}

	if (
		answerFormat === NLPSearchResultFormat.JSON &&
		parsedType === NLPSearchJSONResultFormat.WORK
	) {
		return 'work';
	}

	return 'SAIN';
};

type GetParsedTypeParams = {
	answerFormat: NLPSearchResultFormat | null;
	answerString: string;
	assistanceServiceEnabled?: boolean;
};

export const getParsedType = ({
	answerFormat,
	answerString,
	assistanceServiceEnabled = false,
}: GetParsedTypeParams): NLPSearchJSONResultFormat | undefined => {
	if (answerFormat !== NLPSearchResultFormat.JSON) {
		return undefined;
	}

	return assistanceServiceEnabled
		? getTypedJSONResultDataAssistanceService(answerString).parsedType
		: getTypedJSONResultData(answerString).parsedType;
};

const sanitizeSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.map((source) => ({
		id: source.ari ?? source.id,
		type: source.type,
	}));

const has3PSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.filter((source) => source.ari?.includes('third-party'));

// New instrumentation attributes must be added to this function to be included in all the events
export const filterCommonAttributes = (props: commonAttributesType) => {
	const {
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUps,
		sources,
		extraAttributes,
		searchSessionId,
		queryHash,
		baseQueryHash,
		query,
		answerString,
		apiSource,
		brand,
		singleInstrumentationId,
	} = props;
	return {
		searchSessionId,
		queryHash,
		baseQueryHash,
		wordCount: (query.match(/\w+/g) || []).length,
		queryLength: query.length,
		answerHash: sha256Hash(answerString),
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUpsCount: followUps.length,
		followUpsLength: followUps.map((followUp) => followUp.length),
		sources: sanitizeSources(sources),
		has3PSources: has3PSources(sources).length ? true : false,
		apiSource,
		brand,
		sessionId: singleInstrumentationId,
		...extraAttributes,
	};
};
