import React, { useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';
import { useDebouncedCallback } from 'use-debounce';

import { IconButton } from '@atlaskit/button/new';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

const OFFSET = 250;

const isScrollAtLeft = (elem: HTMLDivElement | null) => elem?.scrollLeft === 0;
const isScrollAtRight = (elem: HTMLDivElement | null) =>
	elem
		? // https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom-not-just-the-window-but-any-element
			Math.abs(elem?.scrollWidth - elem?.scrollLeft - elem?.clientWidth) <= 1
		: false;

const containerStyle = xcss({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
});

const hoverButtonStyle = xcss({
	position: 'absolute',
	zIndex: 'layer',
	cursor: 'pointer',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
});

const leftStyle = xcss({
	background: `linear-gradient(90deg, ${token('elevation.surface.overlay')} 0%, ${token('elevation.surface.overlay')} 80%, rgba(255,0,0,0) 100%)`,
	left: '0',

	':hover': {
		paddingRight: 'space.100',
	},
});

const rightStyle = xcss({
	background: `linear-gradient(270deg, ${token('elevation.surface.overlay')} 0%, ${token('elevation.surface.overlay')} 80%, rgba(255,0,0,0) 100%)`,
	right: '0',

	':hover': {
		paddingLeft: 'space.100',
	},
});

const scrollContainerStyle = xcss({
	minWidth: 'space.0',
	display: 'flex',
	overflowX: 'auto',
	overflowY: 'hidden',
	gap: 'space.100',
});

export const HorizontalScrollContainer = ({ children }: { children: React.ReactNode }) => {
	const ref = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	const [shouldHideLeft, setShouldHideLeft] = useState(false);
	const [shouldHideRight, setShouldHideRight] = useState(false);

	const [onScroll] = useDebouncedCallback(() => {
		setShouldHideLeft(isScrollAtLeft(ref.current));
		setShouldHideRight(isScrollAtRight(ref.current));
	}, 10);

	useEffect(() => {
		setShouldHideLeft(isScrollAtLeft(ref.current));
		setShouldHideRight(isScrollAtRight(ref.current));
	}, []);

	return (
		<Inline xcss={containerStyle}>
			{!shouldHideLeft && (
				<Box xcss={[hoverButtonStyle, leftStyle]}>
					<IconButton
						shape="circle"
						appearance="subtle"
						icon={ChevronLeftLargeIcon}
						isTooltipDisabled
						label={formatMessage(messages.scrollLeft)}
						onClick={() => {
							ref.current?.scrollBy({
								top: 0,
								left: -OFFSET,
								behavior: 'smooth',
							});
						}}
					/>
				</Box>
			)}
			<Box ref={ref} xcss={scrollContainerStyle} onScroll={onScroll}>
				{children}
			</Box>
			{!shouldHideRight && (
				<Box xcss={[hoverButtonStyle, rightStyle]}>
					<IconButton
						shape="circle"
						appearance="subtle"
						icon={ChevronRightLargeIcon}
						isTooltipDisabled
						label={formatMessage(messages.scrollRight)}
						onClick={() => {
							ref.current?.scrollBy({
								top: 0,
								left: OFFSET,
								behavior: 'smooth',
							});
						}}
					/>
				</Box>
			)}
		</Inline>
	);
};
