import React, { Fragment, Suspense, useCallback, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { AISources, useAnalytics } from '../../common';
import { onAIResultActioned } from '../../common/controller/analytics/unified-analytics';
import { QueryIntent } from '../../constants';
import { PersonKnowledgeCard, TeamKnowledgeCard } from '../../knowledge-cards';
import { AIAnswerBody } from '../ai-answer-body';
import { useAIAnswerContext } from '../ai-answer-context';
import { SearchPageSmartAnswerFooter, SmartAnswerFooterSection } from '../ai-answer-footer';
import { AIAnswerLoadingStream } from '../ai-answer-loading/ai-answer-loading-stream';
import GeneratingAnimation from '../ai-answer-loading/generating-animation';
import {
	aiAnswerCollapseClicked,
	aiAnswerExpandClicked,
	aiAnswerExpandShown,
	onAIAnswerLoadingDialogShown,
	onAIAnswerSourceClick,
	onSmartAnswerRouteDetection,
} from '../analytics';
import { NLPSearchResultFormat } from '../types';

import { Collapse } from './collapse';
import { messages } from './messages';
import { AIAnswerSearchBorder, GeneratingContainer, GeneratingText } from './styled';

const spacerStyles = xcss({
	lineHeight: '14px',
	paddingTop: 'space.250',
});

const sourcesContainerStyles = xcss({
	paddingTop: '0',
	paddingRight: 'space.300',
	paddingBottom: 'space.150',
	paddingLeft: 'space.300',
});

export const SmartAnswerLoadingDialog = () => {
	const { commonAttributes, partialResponse, smartAnswersRoute } = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const { formatMessage } = useIntl();

	useEffect(() => {
		fireAnalyticsEvent(
			onSmartAnswerRouteDetection(commonAttributes, smartAnswersRoute || QueryIntent.DEFAULT),
		);
		fireAnalyticsEvent(onAIAnswerLoadingDialogShown(commonAttributes));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// no need for the Collapse component when showing the ... generating animation
	if (!partialResponse || partialResponse?.format !== NLPSearchResultFormat.MARKDOWN) {
		return (
			<AIAnswerSearchBorder isLoading isStreaming={false}>
				<GeneratingContainer>
					<GeneratingAnimation alt={formatMessage(messages.ai_answer_generating_icon_alt_text)} />
					<GeneratingText>
						<FormattedMessage {...messages.ai_answer_generating_rovo} />
					</GeneratingText>
				</GeneratingContainer>
				<SmartAnswerFooterSection />
			</AIAnswerSearchBorder>
		);
	}

	return (
		<AIAnswerSearchBorder isLoading isStreaming>
			<Suspense fallback={<Fragment />}>
				<Collapse collapsed isLoading isStreaming>
					<AIAnswerLoadingStream markdown={partialResponse.partialNlpResult} />
				</Collapse>
				<SmartAnswerFooterSection />
			</Suspense>
		</AIAnswerSearchBorder>
	);
};

const KnowledgeCard = () => {
	const { smartAnswersRoute } = useAIAnswerContext();
	switch (smartAnswersRoute) {
		case QueryIntent.TEAM:
			return <TeamKnowledgeCard />;
		case QueryIntent.PERSON:
			return <PersonKnowledgeCard />;
		default:
			return null;
	}
};

const AnswerCard = () => {
	const { isAnswered, hasError, commonAttributes, callbacks, isReadingAids, smartAnswersRoute } =
		useAIAnswerContext();
	const { searchSessionId, singleInstrumentationId, source, answerFormat, query } =
		commonAttributes;
	const [fireAnalyticsEvent] = useAnalytics();
	const [collapsed, setCollapsed] = useState(true);
	const showKnowledgeCardsv2 = fg('ai_topics_knowledge_cards_v2');

	// reset on each new query
	useEffect(() => {
		setCollapsed(true);
	}, [query]);

	const toggleCollapsedState = () => {
		setCollapsed(!collapsed);
	};

	const analyticsCallbacks = {
		onExpandedByDefault: () => fireAnalyticsEvent(aiAnswerExpandShown(commonAttributes)),
		onExpandClicked: () => fireAnalyticsEvent(aiAnswerExpandClicked(commonAttributes)),
		onCollapseClicked: () => fireAnalyticsEvent(aiAnswerCollapseClicked(commonAttributes)),
	};

	const sourceClickCallback = useCallback(
		(index: number, hostname?: string) => {
			fireAnalyticsEvent(
				onAIAnswerSourceClick({
					sourceIndex: index,
					hostname,
					...commonAttributes,
				}),
			);
			fireAnalyticsEvent(
				onAIResultActioned({
					actionString: 'sourceLinkClicked',
					searchSessionId,
					singleInstrumentationId,
					source,
					isReadingAids: !!isReadingAids,
				}),
			);
			callbacks?.onAIAnswerSourceClick?.();
		},
		[
			fireAnalyticsEvent,
			commonAttributes,
			callbacks,
			singleInstrumentationId,
			isReadingAids,
			searchSessionId,
			source,
		],
	);
	if (
		showKnowledgeCardsv2 &&
		isAnswered &&
		!hasError &&
		(smartAnswersRoute === QueryIntent.PERSON || smartAnswersRoute === QueryIntent.TEAM)
	) {
		return <KnowledgeCard />;
	}

	return (
		<AIAnswerSearchBorder isLoading={false} isStreaming={false}>
			{answerFormat === NLPSearchResultFormat.JSON ? (
				<>
					<Box xcss={spacerStyles}></Box>
					<AIAnswerBody />
				</>
			) : (
				<Collapse
					collapsed={collapsed}
					toggleCollapsedState={toggleCollapsedState}
					expandWhenClickedAnywhere
					isLoading={false}
					callbacks={analyticsCallbacks}
				>
					<AIAnswerBody />
				</Collapse>
			)}
			<Box xcss={sourcesContainerStyles}>
				<AISources
					sources={commonAttributes.sources}
					searchSessionId={commonAttributes.searchSessionId}
					onClickHandler={sourceClickCallback}
					allowFreeflowLayout={true}
				/>
			</Box>
			<SearchPageSmartAnswerFooter showFollowups={!collapsed} />
		</AIAnswerSearchBorder>
	);
};

export const SmartAnswerCard = () => {
	const { loading } = useAIAnswerContext();

	return <>{loading ? <SmartAnswerLoadingDialog /> : <AnswerCard />}</>;
};
