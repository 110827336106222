import React, { useState } from 'react';

import { Inline, xcss } from '@atlaskit/primitives';
import { SmartLinkSize } from '@atlaskit/smart-card';

import { type NLPSearchResultSource } from '../../../ai-answer-dialog/types';
import { ErrorBoundary } from '../error-boundary';

const Card = React.lazy(() =>
	import(/* webpackChunkName: "@atlaskit-internal_.smart-card" */ '@atlaskit/smart-card').then(
		({ Card }) => ({
			default: Card,
		}),
	),
);

const HoverCard = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_.smart-card/hover-card" */ '@atlaskit/smart-card/hover-card'
	).then(({ HoverCard }) => ({
		default: HoverCard,
	})),
);

const fallbackCardStyles = xcss({
	boxDecorationBreak: 'clone',
	backgroundColor: 'color.background.input',
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	color: 'color.link',
	paddingLeft: 'space.025',
	paddingRight: 'space.025',
	whiteSpace: 'pre-wrap',
	wordBreak: 'break-all',
});

const extractUrlHostname = (sourceUrl: string) => {
	let url;
	try {
		url = new URL(sourceUrl);
	} catch {
		return undefined;
	}
	return url.hostname;
};

const fallbackComponent =
	({
		index,
		source,
		searchSessionId,
		onClickHandler,
	}: Omit<SourceCardProps, 'enableSmartLinkSources'>) =>
	() => {
		return (
			<Inline xcss={fallbackCardStyles}>
				<a
					href={`${source.url}?search_id=${searchSessionId}`}
					onClick={(e) => onClickHandler(index, extractUrlHostname(source.url))}
					rel="noopener noreferrer"
					target="_blank"
				>
					<span>
						{source.iconUrl && <img src={source.iconUrl} height={'14px'} width={'14px'} />}
					</span>
					<span>{source.title || source.url}</span>
				</a>
			</Inline>
		);
	};

type SourceCardProps = {
	index: number;
	source: NLPSearchResultSource;
	searchSessionId: string;
	onClickHandler: (index: number, hostname?: string) => void;
	enableSmartLinkSources: boolean;
};

export const SourceCard = ({
	index,
	source,
	searchSessionId,
	onClickHandler,
	enableSmartLinkSources,
}: SourceCardProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const SmartLinkSource = (
		<HoverCard url={source.url} hidePreviewButton>
			<span
				style={{
					display: isVisible ? 'inline' : 'block',
					transform: isVisible ? 'scale(1)' : 'scale(0)',
					height: isVisible ? 'fit-content' : '0px',
					width: isVisible ? 'fit-content' : '0px',
				}}
			>
				<Card
					appearance={'inline'}
					fallbackComponent={fallbackComponent({
						index,
						source,
						searchSessionId,
						onClickHandler,
					})}
					onClick={(e) => {
						onClickHandler(index, extractUrlHostname(source.url));
						e.preventDefault();
						window.open(
							`${source.url}?search_id=${searchSessionId}`,
							'_blank',
							'noopener, noreferrer',
						);
					}}
					onResolve={() => setIsVisible(true)}
					onError={() => setIsVisible(true)}
					url={source.url}
					ui={{
						size: SmartLinkSize.Medium,
					}}
				/>
			</span>
		</HoverCard>
	);

	return (
		<ErrorBoundary fallback={<></>}>
			{enableSmartLinkSources
				? SmartLinkSource
				: fallbackComponent({ index, source, searchSessionId, onClickHandler })()}
		</ErrorBoundary>
	);
};
