import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';

import { useAIAnswerContext } from './ai-answer-context';
import { ReadingAidsOptions } from './reading-aids-options';

const TitleWrapperStyles = xcss({
	justifyContent: 'space-between',
	alignItems: 'center',
	minHeight: '52px',
});

const DefinitionTitleStyles = xcss({
	fontSize: '16px',
	fontWeight: 600,
	color: 'color.text',
	lineHeight: '20px',
	marginTop: 'space.250',
	marginLeft: 'space.300',
});

export const AIAnswerTitle = () => {
	const { commonAttributes, loading, baseQuery } = useAIAnswerContext();
	const { query } = commonAttributes;
	return !loading ? (
		<Flex xcss={TitleWrapperStyles}>
			<Box xcss={DefinitionTitleStyles}>{baseQuery ? baseQuery : query}</Box>
			<ReadingAidsOptions />
		</Flex>
	) : null;
};
