import React, {
	type ComponentType,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { AISources, useAnalytics } from '../../common';
import { onAIResultActioned } from '../../common/controller/analytics/unified-analytics';
import { AIAnswerBody } from '../ai-answer-body';
import { useAIAnswerContext } from '../ai-answer-context';
import { AIAnswerFooter } from '../ai-answer-footer';
import { AIAnswerLoadingDialog } from '../ai-answer-loading/ai-answer-loading-dialog';
import {
	aiAnswerCollapseClicked,
	aiAnswerExpandClicked,
	aiAnswerExpandShown,
	onAIAnswerSourceClick,
} from '../analytics';
import { NLPSearchResultFormat } from '../types';

import { Collapse } from './collapse';
import { AIAnswerSearchBorder } from './styled';

const spacerStyles = xcss({
	lineHeight: '14px',
	paddingTop: 'space.250',
});

const sourcesContainerStyles = xcss({
	paddingTop: '0',
	paddingRight: 'space.300',
	paddingBottom: 'space.100',
	paddingLeft: 'space.300',
});

type LegacySmarAnswerCardProps = {
	LoadingWrapper: ComponentType<PropsWithChildren<{}>>;
	DialogWrapper: ComponentType<PropsWithChildren<{}>>;
};

export const LegacySmartAnswerCard = ({
	LoadingWrapper,
	DialogWrapper,
}: LegacySmarAnswerCardProps) => {
	const { commonAttributes, callbacks, loading, isReadingAids, onClose } = useAIAnswerContext();
	const { searchSessionId, singleInstrumentationId, source, query } = commonAttributes;
	const { answerFormat } = commonAttributes;
	const [fireAnalyticsEvent] = useAnalytics();
	const [collapsed, setCollapsed] = useState(true);
	const isQuickSearch = Boolean(!isReadingAids && onClose);

	// reset on each new query
	useEffect(() => {
		setCollapsed(true);
	}, [query]);

	const toggleCollapsedState = () => {
		setCollapsed(!collapsed);
	};

	const analyticsCallbacks = {
		onExpandedByDefault: () => fireAnalyticsEvent(aiAnswerExpandShown(commonAttributes)),
		onExpandClicked: () => fireAnalyticsEvent(aiAnswerExpandClicked(commonAttributes)),
		onCollapseClicked: () => fireAnalyticsEvent(aiAnswerCollapseClicked(commonAttributes)),
	};

	const sourceClickCallback = useCallback(
		(index: number, hostname?: string) => {
			fireAnalyticsEvent(
				onAIAnswerSourceClick({
					sourceIndex: index,
					hostname,
					...commonAttributes,
				}),
			);
			fireAnalyticsEvent(
				onAIResultActioned({
					actionString: 'sourceLinkClicked',
					searchSessionId,
					singleInstrumentationId,
					source,
					isReadingAids: !!isReadingAids,
				}),
			);
			callbacks?.onAIAnswerSourceClick?.();
		},
		[
			fireAnalyticsEvent,
			commonAttributes,
			callbacks,
			singleInstrumentationId,
			isReadingAids,
			searchSessionId,
			source,
		],
	);

	if (loading) {
		return (
			<LoadingWrapper>
				<AIAnswerLoadingDialog />
			</LoadingWrapper>
		);
	}

	return (
		<DialogWrapper>
			<AIAnswerSearchBorder isLoading={false} isStreaming={false}>
				{isQuickSearch || answerFormat === NLPSearchResultFormat.JSON ? (
					<>
						{answerFormat === NLPSearchResultFormat.JSON && <Box xcss={spacerStyles}></Box>}
						<AIAnswerBody />
					</>
				) : (
					<Collapse
						collapsed={collapsed}
						toggleCollapsedState={toggleCollapsedState}
						expandWhenClickedAnywhere
						isLoading={false}
						callbacks={analyticsCallbacks}
					>
						<AIAnswerBody />
					</Collapse>
				)}
				<Box xcss={sourcesContainerStyles}>
					<AISources
						sources={commonAttributes.sources}
						searchSessionId={commonAttributes.searchSessionId}
						onClickHandler={sourceClickCallback}
					/>
				</Box>
				<AIAnswerFooter />
			</AIAnswerSearchBorder>
		</DialogWrapper>
	);
};
