import React, { useCallback, useEffect, useState } from 'react';

import { FadeIn } from '@atlaskit/motion';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { AISources, ErrorBoundary as CommonErrorBoundary, useAnalytics } from '../../common';
import { onAIResultActioned } from '../../common/controller/analytics/unified-analytics';
import { LoadingAnimation } from '../../common/ui/ai-answer-loading/loading-animation';
import { AiBrand } from '../../constants';
import { AIAnswerBody } from '../ai-answer-body';
import { useAIAnswerContext } from '../ai-answer-context';
import { AIAnswerFooter } from '../ai-answer-footer';
import { AIAnswerLoadingDialog } from '../ai-answer-loading/ai-answer-loading-dialog';
import { AIAnswerTitle } from '../ai-answer-title';
import { onAIAnswerLoadingDialogShown, onAIAnswerSourceClick } from '../analytics';
import { ReadingAidsFollowUps } from '../reading-aids-follow-ups';
import {
	ReadingAidsContainer,
	ReadingAidsPopupContainer,
	ReadingAidsPopupContainerGlow,
	ReadingAidsWrapper,
} from '../styled';

const sourcesContainerStyles = xcss({
	paddingTop: '0',
	paddingRight: 'space.300',
	paddingBottom: 'space.150',
	paddingLeft: 'space.300',
});

const MAX_SOURCES = 4;

const ReadingAidsPopupContent = () => {
	const {
		brand,
		commonAttributes,
		loading,
		isReadingAids,
		callbacks,
		fireAnalyticsEvent,
		readingAidsOptions,
	} = useAIAnswerContext();
	const enableSmartLinkSources = readingAidsOptions?.enableSmartLinkSources;
	const { searchSessionId, source, sources, singleInstrumentationId } = commonAttributes;

	const sourceClickCallback = useCallback(
		(index: number, hostname?: string) => {
			fireAnalyticsEvent(
				onAIAnswerSourceClick({
					sourceIndex: index,
					hostname,
					...commonAttributes,
				}),
			);
			fireAnalyticsEvent(
				onAIResultActioned({
					actionString: 'sourceLinkClicked',
					searchSessionId,
					singleInstrumentationId,
					source,
					isReadingAids: !!isReadingAids,
				}),
			);
			callbacks?.onAIAnswerSourceClick?.();
		},
		[
			fireAnalyticsEvent,
			commonAttributes,
			callbacks,
			singleInstrumentationId,
			isReadingAids,
			searchSessionId,
			source,
		],
	);

	if (loading) {
		return <AIAnswerLoadingDialog />;
	}

	return (
		<FadeIn>
			{(props) => (
				<ReadingAidsWrapper onClick={(e) => e.stopPropagation()} {...props}>
					<AIAnswerTitle />
					<AIAnswerBody />
					<Box xcss={sourcesContainerStyles}>
						<AISources
							sources={sources}
							searchSessionId={searchSessionId}
							onClickHandler={sourceClickCallback}
							limit={MAX_SOURCES}
							verticalLayout={true}
							enableSmartLinkSources={enableSmartLinkSources}
						/>
					</Box>
					{brand === AiBrand.ROVO && fg('platform.reading-aids.show-follow-up-in-ui') && (
						<CommonErrorBoundary>
							<ReadingAidsFollowUps />
						</CommonErrorBoundary>
					)}
					<AIAnswerFooter />
				</ReadingAidsWrapper>
			)}
		</FadeIn>
	);
};

export const DefinitionAnswerCard = () => {
	const { loading, readingAidsOptions, commonAttributes } = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const [continueLoadingAnimation, setContinueLoadingAnimation] = useState(true);

	useEffect(() => {
		if (!loading) {
			const countdownRemainingAnimation = setTimeout(() => {
				setContinueLoadingAnimation(false);
			}, 3000);

			return () => clearTimeout(countdownRemainingAnimation);
		}
	}, [loading]);

	const onLoadingAnimationShown = useCallback(() => {
		fireAnalyticsEvent(onAIAnswerLoadingDialogShown(commonAttributes, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fireAnalyticsEvent]);

	return readingAidsOptions?.loadingHighlightOption && loading ? (
		<LoadingAnimation
			{...readingAidsOptions.loadingHighlightOption}
			onAnimationShown={onLoadingAnimationShown}
		/>
	) : (
		<>
			{loading && <ReadingAidsPopupContainerGlow />}
			<ReadingAidsPopupContainer isLoading={loading} continueAnimation={continueLoadingAnimation}>
				<ReadingAidsContainer>
					<ReadingAidsPopupContent />
				</ReadingAidsContainer>
			</ReadingAidsPopupContainer>
		</>
	);
};
