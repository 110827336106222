import { type ActivityClient, type ActivityItem } from '@atlassian/recent-work-client';

import {
	NLPSearchErrorState,
	NLPSearchResultFormat,
	type NLPSearchTeamResultType,
	type NLPSearchWhoResultCollaboratorDataType,
	type NLPSearchWorkResultRecentActivityDataType,
	type SearchAIAnswerQueryType,
} from '../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../common/fetch';
import { trimARI } from '../../util';

import {
	type KnowledgeCardTeamSearchMemberNodeType,
	type KnowledgeCardTeamSearchNodeType,
	KnowledgeCardTeamSearchQuery,
	type KnowledgeCardTeamSearchType,
	type KnowledgeCardTeamSearchVariables,
} from './index';

type TeamSectionType = KnowledgeCardTeamSearchNodeType;

type ActivitySectionType = ActivityItem[];

type TeamKnowledgeCardVariables = {
	teamQuery: string;
	orgId: string;
	tenantId: string;
	activityClient: ActivityClient;
};

export const fetchTeamKnowledgeCardData = ({
	activityClient,
	teamQuery,
	orgId,
	tenantId,
}: TeamKnowledgeCardVariables): Promise<SearchAIAnswerQueryType> => {
	let teamResult: TeamSectionType | undefined;
	let activityResult: ActivitySectionType | undefined;

	const onError = (error: any) => {
		return Promise.reject(error);
	};

	try {
		return fetchAggResponse<KnowledgeCardTeamSearchVariables, KnowledgeCardTeamSearchType>({
			graphQuery: KnowledgeCardTeamSearchQuery,
			variables: {
				teamQuery,
				orgId: `ari:cloud:platform::org/${orgId}`,
				tenantId,
			},
		})
			.then(({ data, errors }) => {
				if (
					!errors &&
					data?.team.teamSearchV2.nodes &&
					data.team.teamSearchV2.nodes.length > 0 &&
					data.team.teamSearchV2.nodes[0]
				) {
					teamResult = data.team.teamSearchV2.nodes[0];
				} else {
					throw new Error('No team found');
				}
				const actors =
					teamResult?.team?.members?.nodes
						?.map((node) => (node.member ? node.member.id : ''))
						.filter((member) => !!member) || [];
				return activityClient.fetchActivities(
					[50, 125, 250], // retries
					{
						limit: 20,
						actors,
					},
					['workedOn'],
				);
			}, onError)
			.then((data) => {
				if (data.workedOn && data.workedOn.length > 0) {
					activityResult = data.workedOn;
				} else {
					throw new Error('No activity found');
				}
				const finalResult: NLPSearchTeamResultType = {
					team_metadata: {
						id: teamResult?.team?.id || '',
						name: teamResult?.team?.displayName || '',
						avatarUrl: teamResult?.team?.smallAvatarImageUrl || null,
						url: teamResult?.team?.id ? `/people/team/${trimARI(teamResult.team.id)}` : null,
						memberCount: teamResult?.memberCount || 0,
						members: teamResult?.team?.members?.nodes
							? sanitizeAndMapTeamMembers(teamResult.team.members.nodes)
							: [],
						recentActivity: activityResult ? sanitizeAndMapTeamRecentWork(activityResult) : [],
					},
				};
				return {
					nlpSearch: {
						nlpResult: JSON.stringify(finalResult),
						uniqueSources: [],
						disclaimer: null,
						errorState: null,
						format: NLPSearchResultFormat.JSON,
					},
				};
			}, onError);
	} catch (error) {
		return Promise.resolve({
			nlpSearch: {
				errorState: NLPSearchErrorState.NetworkError,
				disclaimer: null,
				format: NLPSearchResultFormat.JSON,
				uniqueSources: [],
			},
		});
	}
};

const sanitizeAndMapTeamMembers = (members: KnowledgeCardTeamSearchMemberNodeType[]) => {
	return members.reduce<NLPSearchWhoResultCollaboratorDataType[]>(function (result, memberNode) {
		if (!!memberNode.member) {
			return result.concat({
				name: memberNode.member.name || '',
				picture: memberNode.member.picture || null,
				title: memberNode.member.extendedProfile?.jobTitle || null,
				url: !!memberNode.member.id ? `/people/${trimARI(memberNode.member.id)}` : null,
				accountId: !!memberNode.member.id ? trimARI(memberNode.member.id) : null,
				department: memberNode.member.extendedProfile?.department || null,
				email: memberNode.member.email || null,
			});
		}
		return result;
	}, []);
};

const MAX_CONTRIBUTORS = 3;
const sanitizeAndMapTeamRecentWork = (recentWork: ActivityItem[]) => {
	return recentWork.reduce<NLPSearchWorkResultRecentActivityDataType[]>(function (
		result,
		recentWorkItem,
	) {
		const curRecentWorkItem = {
			title: recentWorkItem.object.name || '',
			url: recentWorkItem.object.url,
			containerName:
				recentWorkItem.containers.length > 0
					? recentWorkItem.containers[0].name
					: recentWorkItem.object.product,
			event: recentWorkItem.eventType as NLPSearchWorkResultRecentActivityDataType['event'],
			timestamp: recentWorkItem.timestamp,
			iconUrl: !!recentWorkItem.object.icon ? recentWorkItem.object.icon.value : '',
			contributors: recentWorkItem.contributors.slice(0, 3).map((contributor) => ({
				name: contributor.name,
				id: contributor.accountId,
			})),
			othersCount:
				recentWorkItem.contributors.length > MAX_CONTRIBUTORS
					? recentWorkItem.contributors.length - MAX_CONTRIBUTORS
					: 0,
		};

		return result.concat(curRecentWorkItem);
	}, []);
};
