import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	definition_footer_defined_by_text: {
		id: 'search_ai.definition_footer_defined_by_text',
		defaultMessage: 'Defined by',
		description: 'Defined by text for who definition was edited by',
	},
	definition_footer_see_edit_history: {
		id: 'search_ai.definition_footer_see_edit_history',
		defaultMessage: 'See all edit history',
		description: 'Text for button to see all edit history',
	},
	definition_footer_like_button_tooltip: {
		id: 'search_ai.definition_footer_like_button_tooltip',
		defaultMessage: 'Good response',
		description: 'Tooltip text for the definition footer like button',
	},
	definition_footer_dislike_button_tooltip: {
		id: 'search_ai.definition_footer_dislike_button_tooltip',
		defaultMessage: 'Bad response',
		description: 'Tooltip text for the definition footer dislike button',
	},
	definition_footer_beta_status_label: {
		id: 'search_ai.definition_footer.beta_status_label',
		defaultMessage: 'Beta',
		description: 'Status text for the beta lozenge',
	},
	definition_footer_content_quality_short: {
		id: 'search_ai.definition_footer_content_quality_short',
		defaultMessage: 'Content quality may vary',
		description: 'Footer disclose - short version',
	},
	definition_footer_powered_by_AI: {
		id: 'search_ai.definition_footer_powered_by_AI',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Footer text for Atlassian Intelligence branding',
	},
	definition_footer_feedback_options_modal_header_feedback_only: {
		id: 'search_ai.definition_footer_feedback_options_modal_header_feedback_only',
		defaultMessage: 'Leave feedback',
		description: 'Header for footer thumbs down feedback options modal with feedback only',
	},
	definition_footer_feedback_options_modal_header: {
		id: 'search_ai.definition_footer_feedback_options_modal_header',
		defaultMessage: 'Correct definition or leave feedback',
		description: 'Header for footer thumbs down feedback options modal',
	},
	definition_footer_feedback_options_edit_definition_option: {
		id: 'search_ai.definition_footer_feedback_options_edit_definition_option',
		defaultMessage: 'I want to fix this definition',
		description: 'Feedback option to edit definition',
	},
	definition_footer_feedback_options_edit_definition_option_subtext: {
		id: 'search_ai.definition_footer_feedback_options_edit_definition_option_subtext',
		defaultMessage: 'Update the definition.',
		description: 'Subtext of feedback option to edit definition',
	},
	definition_footer_feedback_options_leave_feedback_option: {
		id: 'search_ai.definition_footer_feedback_options_leave_feedback_option',
		defaultMessage: 'I want to leave feedback about Definitions',
		description: 'Feedback option to leave feedback',
	},
	definition_footer_feedback_options_leave_feedback_option_subtext: {
		id: 'search_ai.definition_footer_feedback_options_leave_feedback_option_subtext',
		defaultMessage: 'Share your feedback so we can improve these experiences.',
		description: 'Subtext of feedback option to leave feedback',
	},
	definition_footer_feedback_modal_header: {
		id: 'search_ai.definition_footer_feedback_modal_header',
		defaultMessage: 'Help us improve Definitions',
		description: 'Label text for the title of the report feedback collector for edited definition',
	},
	definition_footer_feedback_consent_checkbox_label: {
		id: 'search_ai.definition_footer_feedback_consent_checkbox_label',
		defaultMessage: 'Include my last prompt and response in the feedback.',
		description:
			'Label for the checkbox asking for user consent to include their AI prompt and response in their feedback',
	},
	definition_footer_feedback_report_label: {
		id: 'search_ai.definition_footer_feedback_report_label',
		defaultMessage: 'Was there a problem with this definition? Let us know what happened.',
		description:
			'Label text for the summary section of the report feedback collector for edited definition',
	},
	definition_footer_feedback_report_details_label: {
		id: 'search_ai.definition_footer_feedback_report_details_label',
		defaultMessage: 'Describe the issue',
		description: 'Label text for the summary text box for the report feedback collector',
	},
	definition_footer_feedback_report_empty_option_share_label: {
		id: 'search_ai.definition_footer_feedback_report_empty_option_share_label',
		defaultMessage: 'I want to share...',
		description: 'Label text for the empty feedback type option of the report feedback collector',
	},
	definition_footer_feedback_report_relevance_option_label: {
		id: 'search_ai.definition_footer_feedback_report_relevance_option_label',
		defaultMessage: 'This isn’t relevant or isn’t what I expected',
		description:
			'Label text for the relevance feedback type option of the report feedback collector',
	},
	definition_footer_feedback_report_inaccurate_option_label: {
		id: 'search_ai.definition_footer_feedback_report_inaccurate_option_label',
		defaultMessage: 'This is inaccurate or misleading',
		description:
			'Label text for the inaccurate feedback type option of the report feedback collector',
	},
	definition_footer_feedback_report_offensive_option_label: {
		id: 'search_ai.definition_footer_feedback_report_offensive_option_label',
		defaultMessage: 'This is offensive or harmful',
		description:
			'Label text for the offensive feedback type option of the report feedback collector',
	},
	definition_footer_feedback_report_other_option_label: {
		id: 'search_ai.definition_footer_feedback_report_other_option_label',
		defaultMessage: 'Something else',
		description: 'Label text for the other feedback type option of the report feedback collector',
	},
	definition_footer_feedback_summary_placeholder_label: {
		id: 'search_ai.definition_footer_feedback_summary_placeholder_label',
		defaultMessage: 'Explain the problem you experienced',
		description: 'Label text for the summary placeholder for the report feedback collector',
	},
	definition_footer_feedback_flag_description: {
		id: 'search_ai.definition_footer_feedback_flag_descriptionl',
		defaultMessage: 'We appreciate you taking the time to send this feedback',
		description: 'Description text for the feedback flag in the ai answer component',
	},
});
