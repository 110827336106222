import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl-next';

import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { usePublish } from '@atlassian/conversation-assistant-pubsub';
import { FollowUpButton } from '@atlassian/conversation-assistant-ui-components';

import { useAIAnswerContext } from '../ai-answer-context';

import { messages } from './messages';

const iconWrapperStyles = xcss({
	width: '24px',
	height: '24px',
	display: 'flex',
});

export const ReadingAidsFollowUps = () => {
	const { commonAttributes, onClose } = useAIAnswerContext();
	const { query, answerString, followUps } = commonAttributes;
	const publish = usePublish('ai-mate');
	const { formatMessage } = useIntl();

	const [showAllFollowUps, setShowAllFollowups] = useState(false);
	const canExpand = followUps.length > 1;
	const visibleFollowUps = showAllFollowUps ? followUps : followUps.slice(0, 1);

	const onFollowUpClick = useCallback(
		(followUp: string) => {
			publish({
				type: 'chat-new',
				source: 'reading-aids',
				data: {
					name: followUp.substring(0, 30),
					dialogues: [
						{
							human_message: {
								content: formatMessage(messages.reading_aids_follow_ups_user_initial_query, {
									// query only contains the word that user asked
									// we want to add the word "define" before it so it makes sense
									// when this is moved to the chat
									term: query,
								}),
							},
							agent_message: { content: answerString },
						},
					],
					prompt: followUp,
				},
			});

			onClose?.();
		},
		[query, answerString, publish, formatMessage, onClose],
	);

	if (!followUps?.length) {
		return null;
	}

	return (
		<Box paddingInline="space.300" paddingBlockEnd="space.100">
			<Stack space="space.075">
				{visibleFollowUps.map((followUp, index) => (
					<Inline space="space.050" alignBlock="center">
						<FollowUpButton
							key={followUp}
							followUpIndex={index}
							followUpText={followUp}
							followUpType="UNAVAILABLE"
							experienceId="reading-aids"
							onClick={() => onFollowUpClick(followUp)}
						/>
						{index === 0 && canExpand && (
							<Box
								xcss={iconWrapperStyles}
								onClick={() => {
									showAllFollowUps ? setShowAllFollowups(false) : setShowAllFollowups(true);
								}}
							>
								{showAllFollowUps ? (
									<ChevronDownIcon
										color="currentColor"
										label=""
										testId="collapse-follow-ups-icon"
									/>
								) : (
									<ChevronRightIcon color="currentColor" label="" testId="expand-follow-ups-icon" />
								)}
							</Box>
						)}
					</Inline>
				))}
			</Stack>
		</Box>
	);
};
