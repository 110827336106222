import { logger } from '../common';

export const onEmailCopy = (email: string) => async () => {
	// try catch is for the clipboard function
	try {
		await navigator.clipboard.writeText(email);
	} catch (error) {
		logger.error(error);
	}
};

export function trimARI(ari: string): string {
	return ari.split('/').pop() || '';
}
