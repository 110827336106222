/* eslint @atlaskit/design-system/no-html-anchor: 0 */
import React, { Fragment, useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { onAIResultError } from '../../../common/controller/analytics/unified-analytics';
import {
	ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK,
	ATLASSIAN_AI_TROUBLESHOOT_LINK,
	ATLASSIAN_AI_USAGE_LIMITS_LINK,
	ATLASSIAN_INTELLIGENCE_SEARCH_INFO_LINK,
} from '../../../constants';
import { messages } from '../../../messages';
import { DefinitionError } from '../../../v2/definitions/body/error/definition-error';
import { useAIAnswerContext } from '../../ai-answer-context';
import { NLPSearchErrorState } from '../../types';

import { AIAnswerResultSection, InfoLink } from './styled';

export const AIAnswerError = () => {
	const { formatMessage } = useIntl();
	const {
		advancedSearchUrl,
		commonAttributes: { errorState, searchSessionId, singleInstrumentationId, source },
		isReadingAids,
		fireAnalyticsEvent,
	} = useAIAnswerContext();

	useEffect(() => {
		if (errorState !== NLPSearchErrorState.Default) {
			fireAnalyticsEvent(
				onAIResultError({
					searchSessionId,
					singleInstrumentationId,
					errorMessage: errorState || '',
					isReadingAids: !!isReadingAids,
					source,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const errorMessage = useMemo(() => {
		switch (errorState) {
			case NLPSearchErrorState.NoAnswer:
			case NLPSearchErrorState.NoAnswerSearchResults:
			case NLPSearchErrorState.NoAnswerHydration:
			case NLPSearchErrorState.NoAnswerRelevantContent:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_error_state_no_answer}
						values={{
							learnMoreLink: (
								<InfoLink href={ATLASSIAN_INTELLIGENCE_SEARCH_INFO_LINK} target={'_blank'}>
									{formatMessage(messages.ai_answer_onboarding_banner_tooltip_link)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.NoAnswerKeywords:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_error_state_keyword}
						values={{
							learnMoreLink: (
								<InfoLink href={ATLASSIAN_INTELLIGENCE_SEARCH_INFO_LINK} target={'_blank'}>
									{formatMessage(messages.ai_answer_onboarding_banner_tooltip_link)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.AcceptableUseViolation:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_error_state_acceptable_use}
						values={{
							acceptableUseLink: (
								<InfoLink href={ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK} target={'_blank'}>
									{formatMessage(messages.ai_answer_component_error_state_acceptable_use_link)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.SubjectiveQuery:
				return <FormattedMessage {...messages.ai_answer_component_error_state_subjective_query} />;
			case NLPSearchErrorState.NoAnswerOpenAIRateLimitUserAbuse:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_open_ai_rate_limit_error}
						values={{
							supportLink: (
								<InfoLink href={ATLASSIAN_AI_USAGE_LIMITS_LINK} target={'_blank'}>
									{formatMessage(messages.ai_answer_component_support_link)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.NoAnswerWhoQuestion:
				return <FormattedMessage {...messages.ai_answer_component_error_state_no_who_is_answer} />;
			case NLPSearchErrorState.NetworkError:
			case NLPSearchErrorState.NoAnswerOpenAIResponseError:
			case NLPSearchErrorState.AIDisabled:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_error_state_network}
						values={{
							supportLink: (
								<InfoLink href={ATLASSIAN_AI_TROUBLESHOOT_LINK} target={'_blank'}>
									{formatMessage(messages.ai_answer_component_support_link)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.Default:
			default:
				return (
					<FormattedMessage
						{...messages.ai_answer_component_result_error_text}
						values={{
							advancedSearchLink: !!advancedSearchUrl ? (
								<a href={advancedSearchUrl}>
									{formatMessage(messages.ai_answer_component_result_error_advanced_search_link)}
								</a>
							) : (
								formatMessage(messages.ai_answer_component_result_error_advanced_search_link)
							),
						}}
					/>
				);
		}
	}, [advancedSearchUrl, errorState, formatMessage]);

	return isReadingAids ? (
		<DefinitionError />
	) : (
		<Fragment>
			<AIAnswerResultSection isReadingAids={isReadingAids} isExpanded>
				{errorMessage}
			</AIAnswerResultSection>
		</Fragment>
	);
};
