import React, { type ReactNode, useEffect, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { HorizontalScrollContainer } from '@atlassian/conversation-assistant-ui-components';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { type NLPSearchWhoResultType } from '../ai-answer-dialog/types';
import { getTypedJSONResultDataAssistanceService } from '../ai-answer-dialog/utils/getTypedJSONResultData';
import { useAnalytics } from '../common/controller/analytics';

import { onKnowledgeCardShown } from './analytics';
import { KnowledgeCardsBody } from './knowledge-cards-body';
import { KnowledgeCardsHeader } from './knowledge-cards-header';
import { KnowledgeCardsTopics } from './knowledge-cards-topics';
import { PersonCard } from './person-card';
import { KnowledgeCardsIntentType } from './types';

const styles = xcss({
	padding: 'space.100',
	margin: 'auto',
	marginBottom: 'space.100',
});

export const PersonKnowledgeCard = () => {
	const { commonAttributes, assistanceServiceEnabled, isAdminHubAIEnabled } = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const [selectedUser] = useState<number | undefined>();
	const { answerString, cloudId, singleInstrumentationId } = commonAttributes;

	const showAITopics = isAdminHubAIEnabled && fg('ai_topics_surface_person_knowledge_card');
	const whoResult: NLPSearchWhoResultType = assistanceServiceEnabled
		? ({
				user_metadata: getTypedJSONResultDataAssistanceService(answerString).parsedResult,
			} as NLPSearchWhoResultType)
		: JSON.parse(answerString);
	const userResults = whoResult.user_metadata;
	// BE will always return first object as the primary user result
	const primaryUser = selectedUser !== undefined ? userResults[selectedUser] : userResults[0];

	const ScrollWrapper =
		userResults.length > 3
			? (props: { children: ReactNode }) => (
					<HorizontalScrollContainer>
						<Box xcss={styles}>{props.children}</Box>
					</HorizontalScrollContainer>
				)
			: React.Fragment;

	useEffect(() => {
		fireAnalyticsEvent(
			onKnowledgeCardShown({
				...commonAttributes,
				cardType: KnowledgeCardsIntentType.PERSON,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return selectedUser === undefined && userResults.length > 1 ? (
		// adds horizontal scroll when more than 3 users
		<ScrollWrapper>
			<Inline space="space.300">
				{userResults.map((user, index) => (
					<PersonCard
						name={user.name}
						profilePicUrl={user.avatar_url ?? ''}
						jobTitle={user.job_title}
						isHoverState={false}
						onCardClick={() => {
							if (user.profile_url) {
								window.open(user.profile_url, '_self');
							}
						}}
					/>
				))}
			</Inline>
		</ScrollWrapper>
	) : (
		<Stack space="space.100">
			<KnowledgeCardsHeader
				type={KnowledgeCardsIntentType.PERSON}
				name={primaryUser.name}
				email={primaryUser.email}
				avatarUrl={primaryUser.avatar_url}
				profileUrl={primaryUser.profile_url}
				jobTitle={primaryUser.job_title}
				department={primaryUser.department}
				location={primaryUser.location}
				time={primaryUser.time}
			/>
			{showAITopics && (
				<KnowledgeCardsTopics
					cloudId={cloudId}
					userId={primaryUser.account_id ?? ''}
					userName={primaryUser.name}
					sessionId={singleInstrumentationId}
					fallbackSection={<></>}
					v2styles={true}
				/>
			)}
			<KnowledgeCardsBody
				type={KnowledgeCardsIntentType.PERSON}
				currentUserId={primaryUser.account_id ?? ''}
				recommendedUsers={primaryUser.collaborators || []}
				recentActivity={primaryUser.recentActivity || []}
				profileUrl={primaryUser.profile_url || ''}
			/>
		</Stack>
	);
};
