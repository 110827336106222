import crypto from 'crypto';

import { useMemo } from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export const useSingleInstrumentationId = ({
	userId,
	cloudId,
	source,
}: {
	userId: string;
	cloudId: string;
	source: string;
}): string => {
	return useMemo(
		() =>
			crypto
				.createHash('sha256')
				.update(`${userId}-${cloudId}-${Date.now().toString()}-${source}`)
				.digest('hex'),
		[userId, cloudId, source],
	);
};

export const onAIInteractionInitiated = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'initiated',
		actionSubject: 'aiInteraction',
		source: source,
		eventType: 'track',
		attributes: {
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};

export const onAIResultViewed = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'viewed',
		actionSubject: 'aiResult',
		source: source,
		eventType: 'track',
		attributes: {
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};

export const onAIResultError = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	errorMessage,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	errorMessage?: string;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'error',
		actionSubject: 'aiResult',
		source: source,
		eventType: 'track',
		attributes: {
			aiErrorMessage: errorMessage,
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};

export const onAIInteractionDismissed = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'dismissed',
		actionSubject: 'aiInteraction',
		source: source,
		eventType: 'track',
		attributes: {
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};

export const onAIResultActioned = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	actionString,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	actionString: string;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'actioned',
		actionSubject: 'aiResult',
		source: source,
		eventType: 'track',
		attributes: {
			aiResultAction: actionString,
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};

export const onAIFeedbackSubmitted = ({
	isReadingAids,
	searchSessionId,
	source,
	sourceProduct,
	isThumbsUp,
	singleInstrumentationId,
}: {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	isThumbsUp: boolean;
	singleInstrumentationId: string;
}): AnalyticsEventPayload => {
	return {
		action: 'submitted',
		actionSubject: 'aiFeedback',
		source: source,
		eventType: 'track',
		attributes: {
			aiFeedbackResult: isThumbsUp ? 'up' : 'down',
			aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			proactiveGeneratedAI: 0,
			userGeneratedAI: 1,
			isAIFeature: 1,
			singleInstrumentationId,
		},
	};
};
