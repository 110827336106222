import React, { type MouseEvent, useContext } from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import type { ProviderProps } from '@atlaskit/smart-card';

import { AiBrand, CardTemplate, QueryIntent } from '../constants';

import type {
	AIAnswerCacheType,
	Callbacks,
	commonAttributesType,
	NLPSearchDisclaimerType,
	NLPSearchType,
	ReadingAidsOptions,
} from './types';

export interface AIAnswerContextType {
	loading?: boolean;
	/**
	 * This is the partial response that is shown when a streaming response is received.
	 *
	 * This will only be present when the loading prop is true.
	 *
	 * When a partialResponse is received -- it is expected the full response will be
	 * received in the same format.
	 */
	isAnswered: boolean;
	hasError: boolean;
	onNavigate: (e: MouseEvent) => void;
	updateContextCache: (data: Partial<AIAnswerCacheType>) => void;
	fireAnalyticsEvent: (payload: AnalyticsEventPayload) => void;
	setQuery: (value: string) => void;
	setData: React.Dispatch<React.SetStateAction<NLPSearchType>>;
	userDetails: {
		id?: string;
		name?: string;
	};
	disclaimer: NLPSearchDisclaimerType | null;
	commonAttributes: commonAttributesType;
	setLiked: (liked: boolean | undefined) => void;
	likesDisabled: boolean;
	setLikesDisabled: (likesDisabled: boolean) => void;
	advancedSearchUrl?: string;
	partialResponse?: { format: 'MARKDOWN'; partialNlpResult: string };
	onClose?: () => void;
	initialCollapsed?: boolean;
	liked?: boolean;
	initialReported?: boolean;
	fromFollowUp?: boolean;
	isReadingAids?: boolean;
	readingAidsOptions?: ReadingAidsOptions;
	cardProviderProps?: Omit<ProviderProps, 'children'>;
	baseQuery?: string;
	assistanceServiceEnabled?: boolean;
	smartAnswersRoute?: QueryIntent;
	cardTemplate?: CardTemplate;
	brand: AiBrand;
	answerEditor?: {
		name: string;
		id: string;
	};
	closeParentDialog?: () => void;
	additionalQueryContext?: string;
	callbacks?: Callbacks;
	isAdminHubAIEnabled?: boolean;
}

const UNINITIALIZED_COMMON_ATTRIBUTES: commonAttributesType = {
	cloudId: '',
	query: '',
	queryHash: '',
	baseQueryHash: '',
	source: '',
	searchSessionId: '',
	answerString: '',
	answerLength: 0,
	answerFormat: null,
	answerCardType: 'SAIN',
	sources: [],
	followUps: [],
	errorState: null,
	extraAttributes: {},
	apiSource: 'cc-search-nlp',
	workspaceId: '',
	singleInstrumentationId: '',
	brand: AiBrand.AI,
	orgId: '',
};

export const AIAnswerContext = React.createContext<AIAnswerContextType>({
	loading: undefined,
	isAnswered: false,
	hasError: false,
	advancedSearchUrl: undefined,
	onNavigate: () => {},
	updateContextCache: () => {},
	fireAnalyticsEvent: () => {},
	setQuery: () => {},
	setData: () => {},
	userDetails: {},
	disclaimer: null,
	commonAttributes: UNINITIALIZED_COMMON_ATTRIBUTES,
	initialCollapsed: undefined,
	liked: undefined,
	setLiked: () => {},
	likesDisabled: false,
	setLikesDisabled: () => {},
	initialReported: undefined,
	isReadingAids: false,
	cardProviderProps: undefined,
	baseQuery: undefined,
	smartAnswersRoute: QueryIntent.DEFAULT,
	cardTemplate: CardTemplate.DEFAULT,
	brand: AiBrand.AI,
	answerEditor: undefined,
	closeParentDialog: () => {},
});

export const useAIAnswerContext = () => {
	return useContext(AIAnswerContext);
};
