import React from 'react';

import { useIntl } from 'react-intl-next';

import Link from '@atlaskit/link';

import { messages } from '../../../../../messages';
import { useAIAnswerContext } from '../../../../ai-answer-context';
import {
	onWhoPrimaryViewProfileClick,
	onWhoPrimaryViewProfileContextMenuClick,
} from '../../../../analytics';

import {
	ProfileSectionPrimaryUserHeader,
	ProfileSectionPrimaryUserImg,
	ProfileSectionPrimaryUserInfoTextWrapper,
	ProfileSectionPrimaryUserInfoWrapper,
	ProfileSectionPrimaryUserName,
	ProfileSectionPrimaryUserWrapper,
	ProfileSectionPrimaryViewProfileLinkWrapper,
	ProfileSectionPrimaryWrapper,
} from './styled';
import { ProfileSectionUserInfo, UserInfoTypeFormat } from './user-info';
import { ViewProfileIcon } from './view-profile-icon';

type ProfileSectionProps = {
	name: string;
	email?: string | null;
	avatarUrl?: string | null;
	profileUrl?: string | null;
	jobTitle?: string | null;
	department?: string | null;
	location?: string | null;
	time?: string | null;
};

export const ProfileSection = ({
	name,
	email,
	avatarUrl,
	profileUrl,
	jobTitle,
	department,
	location,
	time,
}: ProfileSectionProps) => {
	const { formatMessage } = useIntl();
	const { commonAttributes, fireAnalyticsEvent, onNavigate } = useAIAnswerContext();
	return (
		<ProfileSectionPrimaryWrapper>
			<ProfileSectionPrimaryUserWrapper>
				<ProfileSectionPrimaryUserImg src={avatarUrl || ''} alt={name} />
				<ProfileSectionPrimaryUserInfoWrapper>
					<ProfileSectionPrimaryUserHeader>
						<ProfileSectionPrimaryUserName>{name}</ProfileSectionPrimaryUserName>
						<ProfileSectionPrimaryViewProfileLinkWrapper>
							<ViewProfileIcon />
							<Link
								href={profileUrl || ''}
								onClick={(event) => {
									onNavigate(event);
									fireAnalyticsEvent(
										onWhoPrimaryViewProfileClick({
											...commonAttributes,
											primaryUserName: name,
										}),
									);
								}}
								onContextMenu={(event) => {
									onNavigate(event);
									fireAnalyticsEvent(
										onWhoPrimaryViewProfileContextMenuClick({
											...commonAttributes,
											primaryUserName: name,
										}),
									);
								}}
							>
								{formatMessage(messages.ai_answer_who_questions_view_profile)}
							</Link>
						</ProfileSectionPrimaryViewProfileLinkWrapper>
					</ProfileSectionPrimaryUserHeader>
					{/* if job title and department are missing, don't need this row */}
					{(jobTitle || department) && (
						<ProfileSectionPrimaryUserInfoTextWrapper>
							<ProfileSectionUserInfo
								userInfo={jobTitle || ''}
								userInfoType={UserInfoTypeFormat.JOBTITLE}
							/>
							<ProfileSectionUserInfo
								userInfo={department || ''}
								userInfoType={UserInfoTypeFormat.DEPARTMENT}
							/>
						</ProfileSectionPrimaryUserInfoTextWrapper>
					)}
					{/* primary user will always at least have email */}
					<ProfileSectionPrimaryUserInfoTextWrapper>
						<ProfileSectionUserInfo
							userInfo={location || ''}
							userInfoType={UserInfoTypeFormat.LOCATION}
						/>
						<ProfileSectionUserInfo
							userInfo={time || ''}
							userInfoType={UserInfoTypeFormat.TIME}
							applyAdditionalTopIconPadding
						/>
						<ProfileSectionUserInfo
							userInfo={email || ''}
							userInfoType={UserInfoTypeFormat.EMAIL}
							applyAdditionalTopIconPadding
						/>
					</ProfileSectionPrimaryUserInfoTextWrapper>
				</ProfileSectionPrimaryUserInfoWrapper>
			</ProfileSectionPrimaryUserWrapper>
		</ProfileSectionPrimaryWrapper>
	);
};
